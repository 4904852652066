.perks {
    padding: 4rem 0;
    min-height: 60vh;
    background: #159AE0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.perks_container {
    height: 100%;
    display: flex;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
}

.perksCard { 
    height: 250px;
    width: 370px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 2.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.perksSquare {
    background: #26EFBF;
    position: absolute;
    width: 60px;
    height: 60px;
    top: -30px;
    left: 30px;
}

.pc_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.6rem;
    
}

.pc_title {
    color: #fff;
    font-family: var(--primary-font);
    font-size: 1.9rem;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;
}

.pc_title span {
    color: #26EFBF;
    text-transform: uppercase;
}
.pc_desc {
    color: #fff;
    font-family: var(--primary-font);
    font-size: 1.15rem;
    font-weight: 500;
    text-transform: uppercase;
}

.pc_details {
    color: rgba(255, 255, 255, 1);
    font-family: var(--primary-font);
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.75rem;
}

@media screen and (max-width: 1300px){
    .perks_container {
        width: 90%;
    }
}

@media screen and (max-width: 1100px){
    .perks_container {
        width: 96%;
    }
}

@media only screen and (max-width: 800px) {
    .perks_container {
        gap: 3rem;
    }
    .perksCard {
        width: 320px;
    }

    .pc_title {
        font-size: 1.6rem;
    }
    .pc_desc {
        font-size: 1.05rem;
    }
    .pc_details {
        font-size: 0.9rem;
    }

}
