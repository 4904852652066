.contact {
    padding: 4rem 0;
    min-height: 60vh;
}

.contact__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.section__header {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #1E1E1E;
}

.contact__cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2.8rem;
    margin-top: 2rem;
}

.contactCard {
    width: 230px;
    height: 290px;
    background: #ffffff;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 0.25rem;
    font-family: var(--primary-font);
    color: var(--text-main);
    transition: all 0.2s ease-out;
}
.contactCard:hover {

}

.contact_img {
    border-radius: 5px;
    width: 100%;
}

.contact_img img {
    width: 100%;
    height: 150px;
    border-radius: 5px;
}

.contactCard h2 {
    font-weight: 700;
    font-size: 18px;
    text-transform: capitalize;
    margin-top: 0.25rem;
    color: #0E92D7;
}

.contactCard h3 {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    opacity: 0.85;
    text-align: center;
    line-height: 105%;
    color: #1CD0B0;
}

.contact_social {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7rem;
}

.contact_social a {
    text-decoration: none;
    cursor: pointer;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    color: var(--text-main);
    transition: ease-in-out 0.2s;
}

.contact_social a:hover {
    /* background: var(--primary-color);
    color: var(--secondary-color); */
    transform: scale(1.15);
}


.contact_social_icon {
    /* font-size: 1.25rem; */
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

    .contact {
        background: none;
    }

}

/* Portrait phones and smaller */
@media (max-width: 480px) {

    .contact {
        background: none;
        margin-top: auto;
    }

}

@media only screen and (max-width:1300px){

}
@media only screen and (max-width:1200px){
    
}
@media only screen and (max-width:1100px){

}
@media only screen and (max-width:992px){
    
}
@media only screen and (max-width:800px){
    
}
@media only screen and (max-width:600px){
    
}