.navbar {
    height: 80px;
    position: fixed;
    padding: 0 2rem;
    z-index: 999;
    top: 0;
    width: 100%;
}
.navbar__main {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.nav_logo img {
    width: 125px
}
  
.nav_title {
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: 700;
    color: white;
}
  
.nav_contents {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
  
.nav__link {
    gap: 2.5rem;
    font-family: var(--secondary-font);
    font-style: normal;
    align-items: center;
    color: #D7E7EB;
    letter-spacing: 0.1em;
    font-size: 0.95rem;
    cursor: pointer;
    opacity: 0.6;
    font-weight: 500;
    text-transform: uppercase;
}

  
.nav__link:hover {
    opacity: 1;
}
  
a {
    text-decoration: none;
}
  
.nav_hamburger {
    display: none;
}
  
.avatarIcon {
    cursor: pointer;
}


.nav_excel {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_excel img {
    width: 125px
}
 
  
@media screen and (max-width: 1200px) {
    .nav_contents {
        gap: 2.25rem;
    }
    .nav__link {
        font-size: 1rem;
    }
}
@media screen and (max-width: 1100px) {
    .nav_contents {
        gap: 2rem;
    }
    .nav__link {
        font-size: 0.9rem;
    }
}
  
@media screen and (max-width: 992px) {
    .nav_hamburger {
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        color: white;
    }
  
    .nav_contents, .nav_excel {
        display: none;
    }
  
    .nam_menu_icon {
        font-size: 1.75rem;
        color: #ECF5F8;
    }
  
    .navbar__mob {
        background-color: #103147;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 0rem 7rem;
        position: relative;
    }
  
    .navbar_mob_close {
        position: absolute;
        top: 1.6rem;
        right: 2rem;
        font-size: 2rem;
        color: white;
    }
  
    .navbar__mobcontents {
        flex: 0.6;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
    }
  
    .navmob__link {
        gap: 2.5rem;
        font-family: var(--secondary-font);
        font-style: normal;
        align-items: center;
        color: #ECF5F8;
        font-size: 1.2rem;
        cursor: pointer;
        text-transform: uppercase;
        opacity: 0.7;
        text-align: center;
    }
  
    .navmob__link:hover {
        opacity: 1;
    }
}
  
@media screen and (max-width: 600px) {
    .navbar {
      padding: 0 1rem;
    }
    .navbar__mob {
      padding: 0 5rem;
      position: relative;
    }
    .navbar_mob_close {
      position: absolute;
      font-size: 2rem;
      color: white;
    }  
}