.footer {
  background: #12374F;
  height: 35vh;
  padding: 2rem 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.footer_img {
  gap: 3rem;
  display: flex;
}

.excel-logo {
  width: 200px;
}

.mec-logo {
  width: 200px;
}

.footer_heart {
  font-size: 1rem;
  margin: 0 0.5rem;
}

.footer_desc_desktop {
  font-size: 1rem;
  font-family: var(--primary-font);
  font-weight: 400;  
  color: #fff;
}

.footer_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.footer_socialicon {
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.fsocialicon {
  flex-shrink: 0;
  cursor: pointer;
  font-size: 1.5rem;
  color: #ECF5F8;
}

.footer_container {
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
}

.footer_link {
  font-size: 1rem;
  font-family: var(--primary-font);
  color: #ECF5F8;
  font-weight: 500;
}

.footer_desc_mobile {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .footer {
    padding: 2rem 2.5rem;
  }

  .footer_container {
    gap: 1rem;
  }

} 
@media screen and (max-width: 992px) {
   .footer {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 1.5rem;
      height: 100%;
   }

   .footer_left {
      gap: 0rem;
      width: 100%;
   }

   .footer_container {
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

   .footer_socialicon {
    margin-top: 1rem;
    align-self: center;
   }

   .footer_desc_desktop {
    display: none;
    color: #ECF5F8;
   }

   .footer_right {
    align-items: center;
    justify-content: center;
   }

   .footer_desc_mobile {
    display: flex;
    font-size: 1rem;
    font-family: var(--primary-font);
    font-weight: 400;  
    color: #ECF5F8;
   }

   .footer_img {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  }

} 

@media screen and (max-width: 800px) {
   .excel-logo {
    width: 130px;
   }

   .mec-logo {
    width: 130px;
   }

   .fsocialicon {
    font-size: 1.2rem;
   }

   .footer_container {
    gap: 0.8rem;
   }

   .footer_link {
    font-size: 0.9rem;
  }

  .footer_desc_mobile {
    font-size: 0.9rem;
    margin-bottom: -1rem;
    margin-top: 1rem;
  }
}